import { NETWORK_ID } from "../../envs";
import Button from "./button";
import TxLoader from "./txLoader";
import Typography from "./typography";

export type TxPendingProps = {
  loading: boolean;
  result: any;
  onContinue: (e?: any) => void;
};

const TxPending = ({ loading, result, onContinue }: TxPendingProps) => {
  return (
    <div className="w-[400px] relative text-center px-8 py-4 pt-6 flex flex-col justify-center items-center gap-6">
      {loading ? (
        <>
          <TxLoader />

          <div className="flex flex-col w-full">
            <Typography size="lg" weight="semibold" variant="secondary">
              Processing transaction
            </Typography>
            <Typography size="sm" variant="secondary">
              Please, make sure to confirm the transaction on the mobile
              application
            </Typography>
          </div>
        </>
      ) : (
        <>
          <div className="flex flex-col w-full">
            <Typography size="lg" weight="semibold" variant="secondary">
              {String(result?.value?.status).toLowerCase() ===
              "committedSuccess".toLowerCase()
                ? "Transaction successful"
                : "An error as occured"}
            </Typography>

            {result?.value?.transactionIntentHash && (
              <a
                target="_blank"
                rel="noreferrer"
                href={`https://${
                  NETWORK_ID === 2 ? "stokenet-" : ""
                }dashboard.radixdlt.com/transaction/${
                  result.value.transactionIntentHash
                }/summary`}
                className="cursor-pointer"
              >
                <Typography variant="tertiary" weight="bold">
                  Details
                </Typography>
              </a>
            )}

            {result?.error?.error && (
              <Typography variant="secondary">{result.error.error}</Typography>
            )}
          </div>

          <Button
            label={"Close"}
            action={() => onContinue()}
            variant="tertiary"
            className="w-full"
          />
        </>
      )}
    </div>
  );
};

export default TxPending;
