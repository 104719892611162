import {
  ManifestBuilder,
  RadixEngineToolkit,
  TransactionManifest,
  ValueKind,
  address,
  array,
  bool,
  bucket,
  decimal,
  enumeration,
  expression,
  nonFungibleLocalId,
  proof,
  tuple,
} from "@radixdlt/radix-engine-toolkit";
import { TOKENS_ADDRESSES } from "../constants";
import {
  LENDING_MARKET_CDP_RESOURCE,
  LENDING_MARKET_COMPONENT,
  NETWORK_ID,
} from "../envs";
import { CDP, Decimal } from "../types/types";

export async function borrow(
  borrowerAccount: string,
  cdp: string,
  borrowResource: string,
  borrowAmount: Decimal
) {
  const manifest = new ManifestBuilder()
    .callMethod(borrowerAccount, "create_proof_of_non_fungibles", [
      // lending market cdp resource
      address(LENDING_MARKET_CDP_RESOURCE),
      // cdp id
      array(ValueKind.NonFungibleLocalId, nonFungibleLocalId(cdp)),
    ])
    .popFromAuthZone((builder, proof_id) =>
      builder
        .callMethod(LENDING_MARKET_COMPONENT, "borrow", [
          proof(proof_id),
          array(
            ValueKind.Tuple,
            tuple(
              address(borrowResource),
              decimal(borrowAmount.toDecimalPlaces(18))
            )
          ),
        ])
        .callMethod(borrowerAccount, "deposit_batch", [
          // entire worktop
          expression("EntireWorktop"),
        ])
    )
    .build();

  const convertedInstructions = await RadixEngineToolkit.Instructions.convert(
    manifest.instructions,
    NETWORK_ID,
    "String"
  );

  console.debug("MANIFEST INSTRUCTIONS:", convertedInstructions.value);
  return convertedInstructions;
}

export async function repay(
  repayierAccount: string,
  cdpId: string,
  borrowedResource: string,
  borrowedAmount: Decimal
) {
  const manifest: TransactionManifest = new ManifestBuilder()
    .callMethod(repayierAccount, "withdraw", [
      // borrowed resource
      address(borrowedResource),
      // borrow repay amount
      decimal(borrowedAmount.toDecimalPlaces(18)),
    ])
    .takeAllFromWorktop(borrowedResource, (builder, bucket1) =>
      builder
        .callMethod(repayierAccount, "create_proof_of_non_fungibles", [
          // lending market cdp resource
          address(LENDING_MARKET_CDP_RESOURCE),
          // cdp id
          array(ValueKind.NonFungibleLocalId, nonFungibleLocalId(cdpId)),
        ])
        .popFromAuthZone((builder, proof_id) =>
          builder
            .callMethod(LENDING_MARKET_COMPONENT, "repay", [
              // repay proof
              proof(proof_id),
              enumeration(0),
              // borrowed bucket
              array(ValueKind.Bucket, {
                kind: ValueKind.Bucket,
                value: bucket1,
              }),
            ])
            .callMethod(repayierAccount, "deposit_batch", [
              // entire worktop
              expression("EntireWorktop"),
            ])
        )
    )
    .build();

  const convertedInstructions = await RadixEngineToolkit.Instructions.convert(
    manifest.instructions,
    NETWORK_ID,
    "String"
  );

  console.debug("MANIFEST INSTRUCTIONS:", convertedInstructions.value);
  return convertedInstructions;
}

export async function closePosition(cdp: CDP, borrowerAddress: string) {
  let manifest = new ManifestBuilder();

  const collateralArray = cdp.collateral.resources.map((resource) =>
    tuple(
      address(resource.token.address),
      decimal(resource.asset_amount.toDecimalPlaces(18)),
      bool(true)
    )
  );

  manifest
    .callMethod(borrowerAddress, "create_proof_of_non_fungibles", [
      address(LENDING_MARKET_CDP_RESOURCE),
      array(
        ValueKind.NonFungibleLocalId,
        nonFungibleLocalId(cdp.collateral.cdp)
      ),
    ])
    .popFromAuthZone((builder, proof_id) =>
      builder
        .callMethod(LENDING_MARKET_COMPONENT, "remove_collateral", [
          proof(proof_id),
          array(ValueKind.Tuple, ...collateralArray),
        ])
        .callMethod(borrowerAddress, "deposit_batch", [
          expression("EntireWorktop"),
        ])
    );

  const buildedManifest = manifest.build();

  const convertedInstructions = await RadixEngineToolkit.Instructions.convert(
    buildedManifest.instructions,
    NETWORK_ID,
    "String"
  );

  console.debug("MANIFEST INSTRUCTIONS:", convertedInstructions.value);
  return convertedInstructions;
}

export async function supply(
  lenderAccount: string,
  suppliedTokenAddress: string,
  supplyAmount: Decimal,
  cdpId: string | undefined
) {
  const collateralResource = Object.entries(TOKENS_ADDRESSES).find(
    ([_, val]) => val.address === suppliedTokenAddress
  )?.[1].rt;

  if (!collateralResource) {
    console.error(
      "Collateral Resource not found for resource ",
      suppliedTokenAddress
    );
    return;
  }

  let manifest;

  if (!cdpId) {
    manifest = new ManifestBuilder()
      .callMethod(lenderAccount, "withdraw", [
        address(suppliedTokenAddress),
        decimal(supplyAmount.toDecimalPlaces(18)),
      ])
      .takeAllFromWorktop(suppliedTokenAddress, (builder, buck) =>
        builder
          .callMethod(LENDING_MARKET_COMPONENT, "contribute", [bucket(buck)])
          .takeAllFromWorktop(collateralResource, (builder2, buck2) =>
            builder2
              .callMethod(LENDING_MARKET_COMPONENT, "create_cdp", [
                enumeration(0),
                enumeration(0),
                enumeration(0),
                array(ValueKind.Bucket, bucket(buck2)),
              ])
              .callMethod(lenderAccount, "deposit_batch", [
                // entire worktop
                expression("EntireWorktop"),
              ])
          )
      )
      .build();
  } else {
    manifest = new ManifestBuilder()
      .callMethod(lenderAccount, "withdraw", [
        address(suppliedTokenAddress),
        decimal(supplyAmount.toDecimalPlaces(18)),
      ])
      .takeAllFromWorktop(suppliedTokenAddress, (builder, buck) =>
        builder
          .callMethod(lenderAccount, "create_proof_of_non_fungibles", [
            // lending market cdp resource
            address(LENDING_MARKET_CDP_RESOURCE),
            // cdp id
            array(ValueKind.NonFungibleLocalId, nonFungibleLocalId(cdpId)),
          ])
          .popFromAuthZone((builder, proof_id) =>
            builder
              .callMethod(LENDING_MARKET_COMPONENT, "add_collateral", [
                proof(proof_id),
                array(ValueKind.Bucket, {
                  kind: ValueKind.Bucket,
                  value: buck,
                }),
              ])
              .callMethod(lenderAccount, "deposit_batch", [
                // entire worktop
                expression("EntireWorktop"),
              ])
          )
      )
      .build();
  }

  const convertedInstructions = await RadixEngineToolkit.Instructions.convert(
    manifest.instructions,
    NETWORK_ID,
    "String"
  );

  console.debug("MANIFEST INSTRUCTIONS:", convertedInstructions.value);

  return convertedInstructions;
}

export async function redeem(
  lenderAccount: string,
  withdrawResource: string,
  withdrawAmount: Decimal,
  cdp: string
) {
  const manifest: TransactionManifest = new ManifestBuilder()
    .callMethod(lenderAccount, "create_proof_of_non_fungibles", [
      address(LENDING_MARKET_CDP_RESOURCE),
      array(ValueKind.NonFungibleLocalId, nonFungibleLocalId(cdp)),
    ])
    .popFromAuthZone((builder, proof_id) =>
      builder
        .callMethod(LENDING_MARKET_COMPONENT, "remove_collateral", [
          proof(proof_id),
          array(
            ValueKind.Tuple,
            tuple(
              address(withdrawResource),
              decimal(withdrawAmount.toDecimalPlaces(18)),
              bool(false)
            )
          ),
        ])
        .callMethod(lenderAccount, "deposit_batch", [
          expression("EntireWorktop"),
        ])
    )
    .build();

  const convertedInstructions = await RadixEngineToolkit.Instructions.convert(
    manifest.instructions,
    NETWORK_ID,
    "String"
  );

  console.debug("MANIFEST INSTRUCTIONS:", convertedInstructions.value);
  return convertedInstructions;
}
