import * as DropDown from "@radix-ui/react-dropdown-menu";
import { IoWalletOutline } from "react-icons/io5";
import { useRdtProvider } from "../providers/RdtProvider";
import { Typography } from "../atoms";
import { useDispatch } from "react-redux";
import { setAddress } from "../../app/features/walletSlice";

export default function AccountManager() {
  const { rdt, address } = useRdtProvider();
  const walletData = rdt.walletApi.getWalletData();
  const dispatch = useDispatch();

  if (!walletData?.accounts.length) return <></>;
  return (
    <DropDown.Root>
      <DropDown.Trigger aria-label="wallet">
        <IoWalletOutline className="w-7 h-7" />
      </DropDown.Trigger>
      <DropDown.Portal>
        <DropDown.Content
          sideOffset={10}
          align="end"
          className="z-50 p-2 space-y-4 bg-white border-2 rounded-md shadow-md max-w-56 border-textNavBar "
        >
          {[...walletData?.accounts]
            .sort((account1, account2) =>
              account1.label.localeCompare(account2.label)
            )
            .map((account) => (
              <DropDown.Item
                key={account.address}
                onClick={() =>
                  dispatch(setAddress({ address: account.address }))
                }
                className="flex flex-col p-1 rounded-md focus:outline-none hover:bg-gray-300"
              >
                <Typography
                  size="md"
                  weight="semibold"
                  variant="navbar"
                  className="truncate"
                >
                  {account.label}
                  {account.address === address ? " (current)" : ""}
                </Typography>
                <Typography size="sm" className="truncate" variant="secondary">
                  {account.address}
                </Typography>
              </DropDown.Item>
            ))}
        </DropDown.Content>
      </DropDown.Portal>
    </DropDown.Root>
  );
}