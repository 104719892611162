import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import "./index.css";

import Landing from "./pages/Landing";
import Markets from "./pages/Markets";
import Page404 from "./pages/Page404";
//import Portfolio from "./pages/Portfolio";

import { Layout } from "./components/organism";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { RdtProvider } from "./components/providers/RdtProvider";
import { Provider } from "react-redux";
import { store } from "./app/store";

const queryClient = new QueryClient({
  defaultOptions: { queries: { staleTime: Infinity } },
});

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools />
      <Provider store={store}>
        <RdtProvider>
          <BrowserRouter>
            <Layout>
              <Routes>
                <Route path="/" element={<Landing />} />
                <Route path="/markets" element={<Markets />} />
                {/* <Route
                  path="/portfolio"
                  element={<Portfolio />}
                  errorElement={<Page404 />}
                /> */}

                <Route path="*" element={<Page404 />} />
              </Routes>
            </Layout>
          </BrowserRouter>
        </RdtProvider>
      </Provider>
    </QueryClientProvider>
  </React.StrictMode>
);
