import { preciseDecimal } from "@radixdlt/radix-engine-toolkit";
import {
  ethIcon,
  hugIcon,
  lsuIcon,
  radixIcon,
  tetherIcon,
  usdcIcon,
  xwbtcIcon,
  earlyIcon,
  wowoIcon,
} from "./assets/crypto";
import {
  EARLY,
  HUG,
  LSU,
  WOWO,
  POOL_UNIT_HUG,
  POOL_UNIT_EARLY,
  POOL_UNIT_WOWO,
  POOL_UNIT_LSU,
  POOL_UNIT_USDT,
  POOL_UNIT_XETH,
  POOL_UNIT_XRD,
  POOL_UNIT_XUSDC,
  POOL_UNIT_XWBTC,
  USDT,
  XETH,
  XRD,
  XUSDC,
  XWBTC,
} from "./envs";
import { mul1Icon, mul2Icon, mul3Icon } from "./assets/multipliers";

type Route = {
  path: string;
  label: string;
};

export const ROUTES: Route[] = [
  { path: "/", label: "RootBoard" },
  { path: "/markets", label: "Markets" },
  /*  { path: "/portfolio", label: "Portfolio" }, */
];

export const TOKENS_ORDER = [
  "XRD",
  "xWBTC",
  "xETH",
  "xUSDC",
  "xUSDT",
  "LSU",
  "HUG",
  "EARLY",
  "WOWO",
];

export const POOL_TOKENS = {
  [POOL_UNIT_USDT]: "rtether",
  [POOL_UNIT_XRD]: "rtXRD",
  [POOL_UNIT_XWBTC]: "rxWBTC",
  [POOL_UNIT_XETH]: "rxETH",
  [POOL_UNIT_LSU]: "rLSU",
  [POOL_UNIT_HUG]: "rHUG",
  [POOL_UNIT_EARLY]: "rEARLY",
  [POOL_UNIT_WOWO]: "rWOWO",
  [POOL_UNIT_XUSDC]: "rxUSDC",
};

export const TOKENS = {
  [XRD]: "XRD",
  [XWBTC]: "xWBTC",
  [XETH]: "xETH",
  [XUSDC]: "xUSDC",
  [USDT]: "tether",
  [LSU]: "LSU",
  [HUG]: "HUG",
  [EARLY]: "EARLY",
  [WOWO]: "WOWO",
  ...POOL_TOKENS,
};

export const TOKENS_ADDRESSES = {
  tether: {
    //@ts-ignore
    address: Object.entries(TOKENS).find(([val, key]) => key === "tether")[0],
    //@ts-ignore
    rt: Object.entries(TOKENS).find(([val, key]) => key === "rtether")[0],
  },
  XRD: {
    //@ts-ignore
    address: Object.entries(TOKENS).find(([val, key]) => key === "XRD")[0],
    //@ts-ignore
    rt: Object.entries(TOKENS).find(([val, key]) => key === "rtXRD")[0],
  },

  xWBTC: {
    //@ts-ignore
    address: Object.entries(TOKENS).find(([val, key]) => key === "xWBTC")[0],
    //@ts-ignore
    rt: Object.entries(TOKENS).find(([val, key]) => key === "rxWBTC")[0],
  },

  xETH: {
    //@ts-ignore
    address: Object.entries(TOKENS).find(([val, key]) => key === "xETH")[0],
    //@ts-ignore
    rt: Object.entries(TOKENS).find(([val, key]) => key === "rxETH")[0],
  },

  LSU: {
    //@ts-ignore
    address: Object.entries(TOKENS).find(([val, key]) => key === "LSU")[0],
    //@ts-ignore
    rt: Object.entries(TOKENS).find(([val, key]) => key === "rLSU")[0],
  },
  HUG: {
    //@ts-ignore
    address: Object.entries(TOKENS).find(([val, key]) => key === "HUG")[0],
    //@ts-ignore
    rt: Object.entries(TOKENS).find(([val, key]) => key === "rHUG")[0],
  },
  EARLY: {
    //@ts-ignore
    address: Object.entries(TOKENS).find(([val, key]) => key === "EARLY")[0],
    //@ts-ignore
    rt: Object.entries(TOKENS).find(([val, key]) => key === "rEARLY")[0],
  },
  WOWO: {
    //@ts-ignore
    address: Object.entries(TOKENS).find(([val, key]) => key === "WOWO")[0],
    //@ts-ignore
    rt: Object.entries(TOKENS).find(([val, key]) => key === "rWOWO")[0],
  },



  xUSDC: {
    //@ts-ignore
    address: Object.entries(TOKENS).find(([val, key]) => key === "xUSDC")[0],
    //@ts-ignore
    rt: Object.entries(TOKENS).find(([val, key]) => key === "rxUSDC")[0],
  },
};

export const ALIAS_TOKENS = {
  tether: "xUSDT",
  rtether: "rxUSDT",
  rtXRD: "rXRD",
  XRD: "XRD",
  xWBTC: "xWBTC",
  xETH: "xETH",
  LSU: "LSULP",
  HUG: "HUG",
  EARLY: "EARLY",
  WOWO: "WOWO",
  xUSDC: "xUSDC",
  rxWBTC: "rxWBTC",
  rxETH: "rxETH",
  rEARLY: "rEARLY",
  rWOWO: "rWOWO",
  rLSU: "rLSU",
  rxUSDC: "rxUSDC",
};

export const CGTOKENS = {
  tether: "tether",
  XRD: "radix",
  xWBTC: "bitcoin",
  xETH: "ethereum",
  HUG: "hug",
  EARLY: "early-radix",
  WOWO: "wowo",
  LSU: "caviarnine-lsu-pool-lp",
  xUSDC: "usd-coin",
};

export const CGTOKENS_INV = {
  tether: "tether",
  radix: "XRD",
  bitcoin: "xWBTC",
  ethereum: "xETH",
  hug: "HUG",
  "early-radix": "EARLY",
  wowo: "WOWO",
  "caviarnine-lsu-pool-lp": "LSU",
  "usd-coin": "xUSDC",
};

export const MULTIPLIERS_PATH = {
  supply: {
    tether: mul2Icon,
    XRD: mul1Icon,
    xWBTC: mul1Icon,
    xETH: mul1Icon,
    HUG: mul3Icon,
    EARLY: mul2Icon,
    WOWO: mul2Icon,
    LSU: mul1Icon,
    xUSDC: mul2Icon,
  },
  borrow: {
    tether: mul3Icon,
    XRD: mul3Icon,
    xWBTC: mul3Icon,
    xETH: mul3Icon,
    LSU: mul3Icon,
    xUSDC: mul3Icon,
  },
};

export const ICONS_PATHS: Record<string, string> = {
  tether: tetherIcon,
  rtether: tetherIcon,
  XRD: radixIcon,
  rtXRD: radixIcon,

  xWBTC: xwbtcIcon,
  rxWBTC: xwbtcIcon,

  xETH: ethIcon,
  rxETH: ethIcon,

  HUG: hugIcon,
  rHUG: hugIcon,
  WOWO: wowoIcon,
  rWOWO: wowoIcon,

  EARLY: earlyIcon,
  rEARLY: earlyIcon,

  LSU: lsuIcon,
  rLSU: lsuIcon,

  xUSDC: usdcIcon,
  rxUSDC: usdcIcon,
};

export const COMING_SOON_TOKENS: string[] = [];

export const ZERO_DECIMAL = preciseDecimal(0).value;
