import { FaGithub, FaTelegramPlane } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import Mascotte from "../../assets/images/root_mascotte.png";
import { Typography } from "../atoms";

const CONTACTS = [
  {
    icon: <FaGithub size={25} />,
    label: "Developers",
    url: "https://github.com/root-finance/hrc-smart_contracts",
  },
  {
    label: "Docs",
    url: "http://docs.rootfinance.xyz",
  },
];

const SOCIALS = [
  // {
  //   icon: <FaMedium size={25} />,
  //   url: "https://www.medium.com/",
  // },
  {
    icon: <FaTelegramPlane size={25} />,
    url: "https://t.me/ROOTfinanceradix",
  },
  {
    icon: <FaXTwitter size={25} />,
    url: "https://twitter.com/RootFinanceXRD",
  },
];

const POLICIES = [
  {
    label: "Terms of Use",
    url: "https://docs.rootfinance.xyz/terms/terms-of-use",
  },
  {
    label: "Privacy Policy",
    url: "https://docs.rootfinance.xyz/terms/privacy-policy",
  },
];

const Footer = () => (
  <div className="flex flex-col flex-wrap w-full gap-6 px-16 pt-4 pb-8 md:pb-4 bg-footerBg text-textPrimary">
    <div className="flex flex-wrap items-center justify-center w-full gap-8 md:justify-between">
      <div className="flex flex-col gap-6">
        <div className="flex items-center gap-4">
          <img width={120} height={100} src={Mascotte} alt="" />
          <div className="flex flex-col space-y-3">
            <Typography className="text- md:text-lg" size="lg">
              <span className="font-bold">Root</span> Finance
            </Typography>
            <Typography
              className="text-sm italic whitespace-pre-wrap md:text-lg"
              size="lg"
              weight="semibold"
            >
              {"Lending and Borrowing\nhas never been easier"}
            </Typography>

            <Typography className="text-xs md:text-sm" size="sm">
              © 2024 ROOT FINANCE
            </Typography>
          </div>
        </div>
      </div>

      <div className="flex flex-col items-end space-y-4">
        <div className="flex items-center gap-6 flex-nowrap">
          {CONTACTS.map((contact, i) => (
            <a
              key={`footer-contact-btn${i}`}
              className="flex items-center gap-2 transition-colors cursor-pointer hover:text-progressPrimary text-textPrimary"
              href={contact.url}
              target="_blank"
              rel="noreferrer"
            >
              {contact.icon}
              <Typography
                weight="bold"
                className="transition-colors cursor-pointer hover:text-progressPrimary text-textPrimary"
              >
                {contact.label}
              </Typography>
            </a>
          ))}
        </div>

        <div className="flex items-center gap-6 flex-nowrap">
          {SOCIALS.map((social, i) => (
            <a
              key={`footer-social-btn${i}`}
              className="transition-colors cursor-pointer hover:text-progressPrimary text-textPrimary"
              href={social.url}
              target="_blank"
              rel="noreferrer"
            >
              {social.icon}
            </a>
          ))}
        </div>

        <div className="flex items-center gap-6 flex-nowrap">
          {POLICIES.map((policy, i) => (
            <a
              key={`footer-contact-btn${i}`}
              className="flex items-end justify-end w-full gap-2 transition-colors cursor-pointer whitespace-nowrap hover:text-progressPrimary text-textPrimary"
              href={policy.url}
              target="_blank"
              rel="noreferrer"
            >
              <Typography
                size="sm"
                className="transition-colors cursor-pointer hover:text-progressPrimary text-textPrimary"
              >
                {policy.label}
              </Typography>
            </a>
          ))}
        </div>
      </div>
    </div>
  </div>
);

export default Footer;
