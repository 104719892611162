import { Link, useLocation } from "react-router-dom";
import LogoVideo from "../../assets/video/logo_video.mp4";
import RootPointsLogo from "../../assets/images/root_points_logo.png";
import { ROUTES } from "../../constants";
import { Typography } from "../atoms";
import { useEffect, useState } from "react";
import { IoIosMenu, IoMdClose } from "react-icons/io";
import clsx from "clsx";
import AccountManager from "../molecules/AccountManager";
import usePrices from "../hooks/usePrices";
import { formatNumbers } from "../../utils";
import { radixIcon } from "../../assets/crypto";
import useRootPoints from "../hooks/useRootPoints";

type NavBarProps = {
  className?: string;
};
export function Navbar({ className }: NavBarProps) {
  const location = useLocation();
  const [hamburgerOpen, setHamburgerOpen] = useState(false);

  const { data: prices } = usePrices();
  const { data: points } = useRootPoints();

  //console.log("POINTS:", points); //TODO check what points returns and update the value rendered below   \/

  const xrdPrice = prices?.find(
    (price) => price.assetName === process.env.REACT_APP_XRD
  );

  return (
    <nav
      className={`h-fit w-full lg:p-3 p-1 sticky top-0 z-10 flex justify-between items-center lg:px-8 shadow-lg bg-navBarPrimary ${className}`}
    >
      <a
        href={"https://rootfinance.xyz/"}
        className="h-full flex items-center lg:max-w-52 max-h-[70px] max-w-40"
      >
        <video autoPlay muted loop className="w-full h-full">
          <source src={LogoVideo} type="video/mp4" />
        </video>
      </a>

      <div
        className={clsx(
          "flex lg:items-center lg:justify-around justify-between p-10 lg:p-0 transition-all z-[100000] w-full top-0 fixed min-h-[100dvh] lg:min-h-[auto] lg:flex-row flex-col lg:static bg-white lg:bg-none",
          hamburgerOpen ? "left-0 w-full" : "left-full"
        )}
      >
        <button
          className="flex justify-end w-full lg:hidden"
          aria-label="close"
          onClick={() => setHamburgerOpen(false)}
        >
          <IoMdClose className="w-10 h-10" />
        </button>
        <div className={`relative flex lg:flex-row flex-col gap-10 mb-[-3px]`}>
          {ROUTES.map((route, i) => {
            const selected = location?.pathname === route.path;

            return (
              <Link
                className={`transition-all border-navBarSecondary !border-0 
                ${selected ? "border-b-[3px]" : " border-b-[0px]"}`}
                key={`nav${i}`}
                to={route.path}
                onClick={() => setHamburgerOpen(false)}
              >
                <Typography
                  weight="semibold"
                  variant={selected ? "navbar" : "secondary"}
                  className={`transition-colors p-2 ${
                    selected ? "" : "hover:text-textTertiary"
                  }`}
                >
                  {route.label}
                </Typography>
              </Link>
            );
          })}
        </div>

        <div className="flex flex-col gap-16 lg:flex-row lg:items-center">
          {
            <>
              <div className="flex items-center space-x-2">
                <Typography weight="semibold" variant="secondary">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://points.rootfinance.xyz/"
                    className="hover:text-textTertiary"
                  >
                    {/*  //TODO remove these <a></a> components to remove redirect  */}
                    <strong>Root</strong>Points
                  </a>
                </Typography>
                <div className="flex items-center gap-1">
                  <img
                    src={RootPointsLogo}
                    alt="Root points"
                    className="size-7"
                  />
                  {/*   <Typography weight="semibold" variant="secondary">
                    {points ?? 0}  //TODO remove comment here to render points number
                  </Typography> */}
                </div>
              </div>
              {xrdPrice != null && (
                <div className="flex items-center gap-2 animate-fade-in">
                  <div className="relative">
                    <img
                      src={radixIcon}
                      className="object-contain"
                      width={30}
                      height={30}
                      alt=""
                    />
                  </div>

                  <Typography size="sm" variant="secondary">
                    ${formatNumbers(xrdPrice.assetPrice)}
                  </Typography>
                </div>
              )}
            </>
          }
        </div>
      </div>

      <div className="flex items-center space-x-4">
        <AccountManager />
        <radix-connect-button className="z-10 flex h-full px-3 py-5" />
      </div>

      <button
        onClick={() => setHamburgerOpen(true)}
        className="block lg:hidden"
        aria-label="menu"
      >
        <IoIosMenu className="w-8 h-8" />
      </button>
    </nav>
  );
}
