import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const useRootPoints = () => {
  return useQuery({
    enabled: false, //TODO remove this line when endpoints is up or when it's on testing
    queryKey: ["get_root_points"],
    queryFn: async () => {
      const res = await axios.get<any>( //TODO not mandatory, but is good usecase to remove any as type
        `https://points.rootfinance.xyz/`, //TODO update url with the endpoint one example "https://points.rootfinance.xyz/userId" --> which returns {points: 200} as response
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      return res?.data; //TODO check what "res" returns and update this accordingly, then go to navbar component (src/components/organism).
    },
    refetchInterval: 30000,
  });
};

export default useRootPoints;
