import { preciseDecimal } from "@radixdlt/radix-engine-toolkit";
import {
  ALIAS_TOKENS,
  ICONS_PATHS,
  POOL_TOKENS,
  TOKENS,
  TOKENS_ADDRESSES,
  ZERO_DECIMAL,
} from "./constants.tsx";
import { LENDING_MARKET_CDP_RESOURCE } from "./envs.ts";
import {
  Asset,
  CDP,
  Collateral,
  Loan,
  PoolState,
  Price,
  ProtocolData,
  Token,
} from "./types/types.ts";
import { WalletState } from "./app/features/walletSlice.ts";
import {
  NonFungibleResourcesVaultCollection,
  StateNonFungibleDetailsResponseItem,
} from "@radixdlt/babylon-gateway-api-sdk";

export function getToken(
  resource_address: string,
  protocolData?: ProtocolData,
  poolState?: PoolState,
  prices?: Price[]
): Token {
  const id = TOKENS[
    resource_address as keyof typeof TOKENS
  ] as keyof typeof TOKENS_ADDRESSES;

  let asset: Asset | undefined;

  if (protocolData) {
    asset = Array.from(Object.entries(protocolData.assets)).find(
      ([_, asset]) => asset.resource === resource_address
    )?.[1];
  }

  const assetPoolState = poolState?.states.find(
    (state) => state.address === resource_address
  );

  return {
    address: resource_address,
    id,
    name: ALIAS_TOKENS[
      TOKENS[
        resource_address as keyof typeof TOKENS
      ] as keyof typeof ALIAS_TOKENS
    ],
    imgPath: ICONS_PATHS[TOKENS[resource_address as keyof typeof TOKENS]],
    isRtToken: !!POOL_TOKENS[resource_address as keyof typeof POOL_TOKENS],
    rtTokenAddress: TOKENS_ADDRESSES[id]?.rt,
    unitToAssetRatio: preciseDecimal(assetPoolState?.unit_to_asset_ratio || "1")
      .value,
    ratioLoan: assetPoolState?.ratio_loan ?? preciseDecimal(1).value,
    ratioDeposit: assetPoolState?.ratio_deposit ?? preciseDecimal(1).value,
    totalReserved: assetPoolState?.total_reserved_amount ?? ZERO_DECIMAL,
    totalDeposit: assetPoolState?.total_deposit ?? ZERO_DECIMAL,
    protocolData: asset,
    usdPrice:
      prices?.find((price) => price.assetName === resource_address)
        ?.assetPrice || 0,
  };
}

export const getBorrowData = (
  nftsList: StateNonFungibleDetailsResponseItem[],
  tokens: Token[],
  poolState: PoolState
): CDP[] => {
  const res: CDP[] = [];

  for (const obj of nftsList) {
    if (obj.data?.programmatic_json.kind !== "Tuple") continue;

    const colObj = obj.data.programmatic_json.fields.find(
      (item) => item.field_name === "collaterals"
    );

    const loanObj = obj.data.programmatic_json.fields.find(
      (item) => item.field_name === "loans"
    );

    if (colObj?.kind !== "Map") continue;

    const collateral: Collateral = {
      cdp: obj.non_fungible_id,
      resources: colObj.entries.map((entry) => {
        const colObjKey = entry?.key;
        const colObjValue = entry?.value;

        if (colObjKey?.kind !== "Reference") {
          throw new Error("colObjKey?.kind is not Reference ");
        }
        const collateralAddr = colObjKey.value;

        if (colObjValue?.kind !== "PreciseDecimal") {
          throw new Error("colObjValue?.kind is not Decimal");
        }

        const token = tokens.find((token) => token.address === collateralAddr);

        if (!token) {
          throw new Error("Token not listed");
        }

        return {
          asset_amount: preciseDecimal(colObjValue.value).value.div(
            token.unitToAssetRatio
          ),
          pool_units_amount: preciseDecimal(colObjValue.value).value,
          token,
        };
      }),
    };

    if (loanObj?.kind !== "Map") continue;

    let loans: Loan[] = [];

    loanObj.entries.forEach((loan) => {
      let resourceAddr: string;
      let assetAmount;
      let puAmount;

      const loanObjKey = loan.key;
      const loanObjValue = loan.value;

      if (loanObjKey?.kind !== "Reference") return;
      resourceAddr = loanObjKey.value;

      if (loanObjValue?.kind !== "PreciseDecimal") return;

      const token = tokens.find((token) => token.address === resourceAddr);
      if (!token) {
        return;
      }

      const ratioLoan = preciseDecimal(
        poolState.states.find((token) => token.address === resourceAddr)
          ?.ratio_loan ?? ZERO_DECIMAL
      );

      puAmount = preciseDecimal(loanObjValue.value).value;
      assetAmount = preciseDecimal(loanObjValue.value).value.div(
        ratioLoan.value
      );

      loans.push({
        token: token,
        asset_amount: assetAmount,
        pool_units_amount: puAmount,
      });
    });

    res.push({
      loan: loans,
      collateral,
    });
  }

  return res;
};

export const getNonFungibleResources = async (
  nonFungibleObj: NonFungibleResourcesVaultCollection,
  rdt: WalletState["rdt"]
) => {
  const itemList = nonFungibleObj.items.map((item) => ({
    resource: item.resource_address,
    items: item.vaults.items[0].items ?? [],
  }));

  let result: StateNonFungibleDetailsResponseItem[] = [];
  for (const { resource, items } of itemList) {
    if (resource === LENDING_MARKET_CDP_RESOURCE) {
      const res = await rdt.gatewayApi.state.getNonFungibleData(
        resource,
        items
      );
      result = res;
    }
  }

  return result
    .sort((a: any, b: any) => b.allowedCollateral - a.allowedCollateral)
    .sort((a: any, b: any) => a.id - b.id);
};

export const formatNumbers = (number: number | string, digits?: number) => {
  if (Number(number) > 1) {
    return new Intl.NumberFormat("en-US", {
      maximumFractionDigits: digits ?? 2,
      roundingMode: "floor",
    }).format(Number(number));
  } else {
    return new Intl.NumberFormat("en-US", {
      maximumSignificantDigits: digits ?? 2,
      roundingMode: "floor",
    }).format(Number(number));
  }
};