import { useState } from "react";
import { repay } from "../../manifest/manifest";
import { formatNumbers } from "../../utils";
import { Button, Modal, TxLoader, TxPending, Typography } from "../atoms";
import Spinner from "../atoms/Spinner";
import useLimits from "../hooks/useLimits";
import useWalletData from "../hooks/useWalletData";
import { useRdtProvider } from "../providers/RdtProvider";
import SliderInput from "./sliderInput";
import { Decimal, Token } from "../../types/types";
import { ZERO_DECIMAL } from "../../constants";

type RepayModal = {
  borrowAsset: Token;
  onClickOutside: (e?: any) => void;
};

const RepayManageModal = ({ borrowAsset, onClickOutside }: RepayModal) => {
  const { rdt } = useRdtProvider();

  const [loading, setLoading] = useState(false);
  const [isSliderLoading, setIsSliderLoading] = useState(false);

  const [amount, setAmount] = useState<Decimal>(ZERO_DECIMAL);
  const [txResult, setTxResult] = useState<object | undefined>(undefined);
  const [txLoading, setTxLoading] = useState(false);

  const { data: walletData } = useWalletData();
  const cdp = walletData?.nfts[0].collateral.cdp;

  const repayOptions =
    cdp && amount
      ? {
          simulateLimit: {
            repay: [{ cdp: cdp, amount, address: borrowAsset.address }],
          },
        }
      : undefined;

  const { data: simulateLimits, isLoading } = useLimits(repayOptions);
  const { address } = useRdtProvider();
  const { data: limits } = useLimits();

  const supplyAmount = walletData?.supplies?.find(
    (supply) => supply.address === borrowAsset?.address
  )?.asset_amount;

  const loan = walletData?.nfts[0]?.loan.find(
    (loan) => loan.token.address === borrowAsset?.address
  );

  const loanValue = loan?.asset_amount ? loan.asset_amount : ZERO_DECIMAL;

  let repayMax = supplyAmount ?? ZERO_DECIMAL;
  if (repayMax.greaterThan(loanValue)) {
    repayMax = loanValue;
  }

  const handleRepay = async () => {
    if (!address) return;
    if (!cdp) return;
    if (!loan?.token.address) return;

    const manifest = await repay(address, cdp, loan.token?.address, amount);
    setTxLoading(true);

    const result = await rdt.walletApi.sendTransaction({
      transactionManifest: manifest.value as string,
    });

    if (result.isErr()) {
      console.error("TxError", result);
    }
    setTxResult(result);

    setTxLoading(false);
  };

  return (
    <Modal
      visible
      onClickOutside={
        txLoading || (txResult && !loading)
          ? () => {
              !txLoading && setTxResult(undefined);
              !txLoading && onClickOutside();
            }
          : () => {
              onClickOutside();
              setTxResult(undefined);
            }
      }
    >
      {txLoading || (txResult && !loading) ? (
        <TxPending
          loading={txLoading}
          result={txResult}
          onContinue={() => {
            setTxResult(undefined);
            onClickOutside();
          }}
        />
      ) : (
        <div className="w-full lg:max-w-[500px] flex flex-col gap-6">
          <div className="flex flex-col items-center justify-center w-full gap-2 px-8 py-4">
            <img
              alt={borrowAsset?.name}
              src={borrowAsset?.imgPath}
              className="object-cover"
              width={30}
              height={30}
            />
            <Typography
              variant="secondary"
              size="sm"
              weight="semibold"
              className="uppercase"
            >
              {borrowAsset?.name}
            </Typography>
          </div>

          <div className="flex flex-col items-center justify-center w-full gap-2 px-8 py-4">
            <div className="flex items-center justify-between w-full gap-4">
              <Typography
                className="whitespace-nowrap opacity-[60%]"
                variant="secondary"
                size="sm"
              >
                Supply amount
              </Typography>
              <Typography
                className="whitespace-nowrap"
                variant="secondary"
                size="sm"
              >
                Repay max {formatNumbers(repayMax.toString(), 7)}{" "}
                {loan?.token?.name}
              </Typography>
            </div>

            {walletData != null ? (
              <SliderInput
                maxValue={repayMax}
                onChange={(value: Decimal) => setAmount(value)}
                onLoadChange={setIsSliderLoading}
                max
              />
            ) : loading ? (
              <TxLoader />
            ) : (
              <div
                className="w-full cursor-pointer"
                onClick={() => {
                  rdt.walletApi.sendRequest();
                  setLoading(true);
                }}
              >
                <Typography variant="navbar">
                  Please connect your wallet
                </Typography>
              </div>
            )}

            <div className="flex flex-col w-full gap-3">
              <Typography
                variant="navbar"
                size="lg"
                className="w-full text-start"
              >
                Supply Stats
              </Typography>
              <div className="flex flex-col w-full">
                {borrowAsset?.protocolData?.lendingAPY != null && (
                  <div className="flex items-center justify-between w-full">
                    <Typography variant="secondary">Supply APY</Typography>
                    <Typography variant="secondary">
                      {formatNumbers(
                        parseFloat(borrowAsset?.protocolData?.lendingAPY)
                      )}
                      %
                    </Typography>
                  </div>
                )}
                {supplyAmount != null && (
                  <div className="flex items-center justify-between w-full">
                    <Typography variant="secondary">Supply Balance</Typography>
                    <Typography variant="secondary">
                      {formatNumbers(supplyAmount.toString(), 7)}{" "}
                      {borrowAsset?.name}
                    </Typography>
                  </div>
                )}
              </div>

              <Typography
                variant="navbar"
                size="lg"
                className="w-full text-start"
              >
                Borrow Limit
              </Typography>
              <div className="flex flex-col w-full pb-4">
                {borrowAsset?.protocolData?.LTVLimit != null && (
                  <div className="flex items-center justify-between w-full">
                    <Typography variant="secondary">LTV</Typography>

                    <Typography variant="secondary">{`${
                      parseFloat(borrowAsset.protocolData.LTVLimit) * 100 - 5
                    }%`}</Typography>
                  </div>
                )}

                <div className="flex items-center justify-between w-full">
                  <Typography variant="secondary">Your Borrow Limit</Typography>
                  {isLoading || isSliderLoading ? (
                    <Spinner width={20} height={20} strokeWidth={3} />
                  ) : (
                    <Typography variant="secondary">{`$${formatNumbers(
                      limits?.totalBorrowLimit || 0
                    )} -> $${formatNumbers(
                      simulateLimits?.totalBorrowLimit || 0
                    )}`}</Typography>
                  )}
                </div>

                <div className="flex items-center justify-between w-full">
                  <Typography variant="secondary">Borrow Limit Used</Typography>
                  {isLoading || isSliderLoading ? (
                    <Spinner width={20} height={20} strokeWidth={3} />
                  ) : (
                    <Typography variant="secondary">{`${formatNumbers(
                      limits?.borrowLimitUsedPercentage || 0
                    )}% -> ${formatNumbers(
                      Math.max(
                        simulateLimits?.borrowLimitUsedPercentage || 0,
                        0
                      )
                    )}%`}</Typography>
                  )}
                </div>
              </div>

              <Button
                action={() => {
                  handleRepay();
                }}
                label="Repay"
                variant="tertiary"
                className="w-full"
                disabled={
                  isLoading || amount.eq(ZERO_DECIMAL) || isSliderLoading
                }
              />
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default RepayManageModal;
