import { useNavigate } from "react-router-dom";
import { Button, Typography } from "../components/atoms";
import Mascotte from "../assets/images/root_mascotte.png";

const Page404 = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center justify-center w-full h-full gap-4">
      <div className="flex flex-col items-center gap-4 p-4 rounded-lg bg-modalBg">
        <img width={120} height={100} src={Mascotte} alt="" />
        <Typography size="md" variant="secondary" className="pb-8">
          Ops! The page you're looking for no longer exists
        </Typography>
        <Button
          variant="tertiary"
          label={"Go to dashboard"}
          action={() => {
            navigate("/");
          }}
        />
      </div>
    </div>
  );
};

export default Page404;
