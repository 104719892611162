import {
  DataRequestBuilder,
  generateRolaChallenge,
  RadixDappToolkit,
} from "@radixdlt/radix-dapp-toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { DAPP_DEFINITION_ADDRESS, NETWORK_ID } from "../../envs";
import { GatewayApiClient } from "@radixdlt/babylon-gateway-api-sdk";

export interface WalletState {
  rdt: {
    walletApi: RadixDappToolkit["walletApi"];
    gatewayApi: GatewayApiClient;
  };
  address?: string;
}

const dAppToolkit = RadixDappToolkit({
  dAppDefinitionAddress: DAPP_DEFINITION_ADDRESS,
  networkId: NETWORK_ID,
  featureFlags: ["ExperimentalMobileSupport"],
  applicationName: "Root Finance",
  applicationVersion: "1.0.0",
});

const basicAuthUsername = "????";
const basicAuthPassword = "????";

const gatewayApi = GatewayApiClient.initialize({
  ...dAppToolkit.gatewayApi.clientConfig,

  headers: {
    Authorization: `Basic ${Buffer.from(
      `${basicAuthUsername}:${basicAuthPassword}`
    ).toString("base64")}`,
  },
});

dAppToolkit.walletApi.provideChallengeGenerator(async () =>
  generateRolaChallenge()
);

dAppToolkit.walletApi.setRequestData(
  DataRequestBuilder.persona().withProof(),
  DataRequestBuilder.accounts().atLeast(1)
);

const initialState: WalletState = {
  rdt: {
    walletApi: dAppToolkit.walletApi,
    gatewayApi: gatewayApi,
  },
};

export const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    setAddress: (state, action: PayloadAction<{ address: string }>) => {
      state.address = action.payload.address;
    },
  },
});

export const { setAddress } = walletSlice.actions;

export default walletSlice.reducer;