import { useCallback, useEffect, useState } from "react";
import Input from "../atoms/input";
import Typography from "../atoms/typography";
import debounce from "lodash.debounce";
import { Decimal } from "../../types/types";
import { preciseDecimal } from "@radixdlt/radix-engine-toolkit";
import { ZERO_DECIMAL } from "../../constants";
import { Button } from "../atoms";
import { formatNumbers } from "../../utils";

export interface SliderProps {
  mini?: boolean;
  light?: boolean;
  maxValue: Decimal;
  onChange: (e?: any) => void;
  onLoadChange: (e?: any) => void;
  max?: boolean;
}

const SliderInput = ({
  mini,
  light,
  maxValue,
  onChange,
  onLoadChange,
  max,
}: SliderProps) => {
  const values = [0, 25, 50, 75, 100];

  const [value, setValue] = useState<Decimal>(ZERO_DECIMAL);
  const [showValue, setShowValue] = useState<string>("");

  const parseStringCommas = (startingString: string) => {
    let regex = new RegExp(",", "g");
    let replacedString = startingString.replace(regex, "");
    return replacedString;
  };

  const checkInputs = (inputValue: Decimal) => {
    let newValue: Decimal = inputValue;
    if (inputValue.lessThanOrEqualTo(ZERO_DECIMAL)) {
      newValue = ZERO_DECIMAL;
    }

    if (inputValue.greaterThan(maxValue)) {
      newValue = maxValue;
    }

    if (!value.eq(newValue)) {
      setValue(newValue);
    }

    const formattedNewValue =
      newValue.toString() === "0"
        ? ""
        : parseStringCommas(formatNumbers(newValue.toString(), 7));

    if (showValue !== formattedNewValue) {
      setShowValue(formattedNewValue);
    }

    onLoadChange(false);
    onChange(newValue);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const delayedHealthCheck = useCallback(debounce(checkInputs, 1000), [value]);

  const handleInputChange = (inputValue: string) => {
    if (inputValue.includes(",")) {
      return;
    }

    let newVal;
    try {
      newVal = preciseDecimal(inputValue).value;
    } catch {
      setShowValue(
        value.toString() === "0"
          ? ""
          : parseStringCommas(formatNumbers(value.toString(), 7))
      );

      onLoadChange(false);
      return;
    }

    setShowValue(inputValue);
    setValue(newVal);
    onLoadChange(true);
  };

  const handleSliderChange = (inputValue: Decimal) => {
    setValue(inputValue);
    setShowValue(
      inputValue.toString() === "0"
        ? ""
        : parseStringCommas(formatNumbers(inputValue.toString(), 7))
    );
    onLoadChange(true);
  };

  useEffect(() => {
    delayedHealthCheck(value);
    return delayedHealthCheck.cancel;
  }, [value, delayedHealthCheck]);

  return (
    <div className="flex flex-col w-full gap-6 px-4 pt-4 pb-10">
      <div className="flex items-center w-full gap-2">
        <Input
          type="text"
          value={showValue}
          onChange={(val) => handleInputChange(val)}
        />
        {max && (
          <Button
            label="Max"
            variant="max"
            disabled={value.eq(maxValue)}
            action={() => handleSliderChange(maxValue)}
          />
        )}
      </div>

      <div
        className={`py-2 w-full h-fit ${
          mini ? "min-w-[150px]" : "min-w-[350px]"
        }`}
      >
        <div className="relative h-[2px] bg-slate-500 w-full">
          {values.map((val, i) => {
            return (
              <div
                key={`progress-${i}`}
                className={`absolute top-px ${
                  i !== 0 && values.length !== i + 1 ? "-translate-x-[50%]" : ""
                } -translate-y-[50%] rounded-full border-2 border-textTertiary bg-textSecondary`}
                style={{
                  [values.length === i + 1 ? "right" : "left"]:
                    values.length === i + 1 ? 0 : `${val}%`,
                  top: 1,
                  width: mini ? 6 : 9,
                  height: mini ? 6 : 9,
                }}
              />
            );
          })}

          <div className="relative range-input">
            <input
              onChange={(e: any) =>
                handleSliderChange(
                  preciseDecimal(Number(e.target.value)).value.toDecimalPlaces(
                    36
                  )
                )
              }
              type="range"
              min={0}
              step={1 / 10 ** 36}
              max={Number(maxValue.toDecimalPlaces(36))}
              value={(maxValue.equals(0) ? ZERO_DECIMAL : value).toString()}
              className={`range-min absolute w-full top-px -translate-y-[50%] bg-transparent appearance-none cursor-pointer ${
                mini ? "h-[10px]" : "h-[15px]"
              }`}
            />
          </div>

          {values.map((val, i) => (
            <Typography
              key={`percent-${i}`}
              className={`absolute top-[15px] opacity-[60%]`}
              style={{
                left: `calc(${val}% - 7px)`,
              }}
              variant={light ? "primary" : "secondary"}
              size={mini ? "xs" : "md"}
            >
              {val}%
            </Typography>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SliderInput;
