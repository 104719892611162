import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const useHugApy = () => {
  return useQuery({
    queryKey: ["hug_apy"],
    queryFn: async () => {
      const res = await axios.get("https://receive.hug.meme/api/root");
      return res?.data;
    },
    refetchInterval: 30000,  // Fetch every 30 seconds
  });
};

export default useHugApy;
