import {
  Card,
  /*  Input, */ Table,
  TxLoader,
  Typography,
} from "../components/atoms";
/* import SearchIcon from "../assets/icons/search"; */
import RootPointsLogo from "../assets/images/root_points_logo.png";

import { useEffect, useState } from "react";
import { FaWallet } from "react-icons/fa";
import useProtocolData from "../components/hooks/useProtocolData";
import useWalletData from "../components/hooks/useWalletData";
import {
  BorrowManageModal,
  RepayManageModal,
  SupplyManageModal,
} from "../components/molecules";
import HealthBar from "../components/organism/HealthBar";
import {
  COMING_SOON_TOKENS,
  ICONS_PATHS,
  MULTIPLIERS_PATH,
  ZERO_DECIMAL,
} from "../constants";
import { Token } from "../types/types";
import { formatNumbers } from "../utils";
import useLimits from "../components/hooks/useLimits";
import useHugApy from "../components/hooks/useHugApy";
import WowoBank from "../assets/images/wowobank.png";
import bank from "../assets/images/bank.png";
type ModalState = {
  modal?: "borrow" | "withdraw" | "supply" | "repay";
  token: Token;
};

const Landing = () => {
  const [modalConfig, setModalConfig] = useState<ModalState>();

  const {
    data,
    refetch: refetchProtocol,
    isLoading: protocolDataIsLoading,
    isRefetching,
    error: protocolDataError,
  } = useProtocolData();
  const {
    data: walletData,
    refetch: refetchWallet,
    isLoading: walletDataIsLoading,
    isFetched,
    error: walletDataError,
  } = useWalletData();
  const { data: hugData } = useHugApy();

  const {
    data: limits,
    isLoading: healthBarLoading,
    refetch: refetchHealthBar,
  } = useLimits();

  useEffect(() => {
    if (isFetched && !isRefetching) {
      refetchWallet();
    }
  }, [isRefetching, isFetched, refetchWallet]);

  const handleRefetch = () => {
    refetchProtocol();
    refetchHealthBar();
  };

  const tokens = data?.tokens;

  if (protocolDataError) {
    console.error(protocolDataError);
  }

  if (walletDataError) {
    console.error(walletDataError);
  }

  const isLoading = protocolDataIsLoading || walletDataIsLoading;

  if (isLoading) {
    return (
      <div className="flex flex-col items-center justify-center grow">
        <TxLoader />
      </div>
    );
  }

  if (!tokens) {
    return (
      <div className="flex flex-col items-center justify-center grow">
        <p className="text-white">No data found</p>
      </div>
    );
  }

  return (
    <>
      <div className="flex flex-col w-full gap-8 ">
        {walletData && (
          <div className="w-full space-y-4 animate-fade-in">
            <div className="grid grid-cols-[70%_30%]  pr-6">
              <Typography size="xl" weight="semibold">
                Summary
              </Typography>
              <Typography
                size="xl"
                weight="semibold"
                className="hidden lg:inline"
              >
                Healthbar
              </Typography>
            </div>
            <HealthBar limits={limits} isLoading={healthBarLoading} />
          </div>
        )}

        <div className="grid grid-cols-1 gap-8 lg:grid-cols-2">
          {walletData && (
            <>
              <div className="flex flex-col w-full gap-4">
                <Typography
                  size="xl"
                  weight="semibold"
                  className="flex items-center space-x-2"
                >
                  <FaWallet />
                  <span>Your Supplies</span>
                </Typography>

                <Card>
                  <Table
                    titles={[
                      "asset",
                      "balance",
                      "APY",
                      <div className="flex items-center space-x-2">
                        <img
                          src={RootPointsLogo}
                          alt="Root points"
                          className="w-6 h-6"
                        />
                        <span>Points</span>
                      </div>,
                      "",
                    ]}
                    values={
                      !!walletData?.nfts?.length
                        ? walletData?.nfts[0]?.collateral.resources.map(
                            ({ token, asset_amount }) => {
                              const multiplier =
                                MULTIPLIERS_PATH.supply[
                                  token.id as keyof typeof MULTIPLIERS_PATH.supply
                                ];
                              const isHug = token.id === "HUG";
                              const isWowo = token.id === "WOWO";
                              return [
                                {
                                  type: "image-text",
                                  value: {
                                    img: token?.imgPath,
                                    text: token?.name,
                                  },
                                },
                                {
                                  type: "text",
                                  value: (
                                    <div className="flex flex-col items-center justify-center space-y-1">
                                      <Typography>
                                        {formatNumbers(asset_amount.toString())}{" "}
                                      </Typography>
                                      <Typography
                                        size="xs"
                                        weight="semibold"
                                        className="text-white/90"
                                      >
                                        $
                                        {formatNumbers(
                                          asset_amount
                                            .mul(token.usdPrice)
                                            .toString()
                                        )}
                                      </Typography>
                                    </div>
                                  ),
                                },
                                isWowo
                                ? {
                                  type: "image-link",
                                  value: {
                                    img: bank,
                                    url:"https://wowoproject.com/wowo-bank",
                                    },
                                  }
                               :{
                                  type: "text",
                                  value: `${formatNumbers(
                                    isHug
                                      ? (hugData?.apy ? hugData.apy * 100 : 0)
                                      : token?.protocolData?.lendingAPY
                                      ? parseFloat(token.protocolData.lendingAPY)
                                      : 0
                                  )} %`,
                                },
                                {
                                  type: "image",
                                  value: multiplier,
                                },
                                {
                                  type: "button",
                                  value: {
                                    label: "Withdraw",
                                    className:
                                      "hover:bg-sky-500 hover:border-sky-500",
                                    onClick: () =>
                                      setModalConfig({
                                        modal: "withdraw",
                                        token: token,
                                      }),
                                  },
                                },
                              ];
                            }
                          )
                        : []
                    }
                    emptyMessage="Nothing supplied yet"
                  />
                </Card>
              </div>
              <div className="box-border flex flex-col w-full gap-4">
                <Typography size="xl" weight="semibold">
                  Your Borrows
                </Typography>
                {/*   <Input onChange={handleSearchRight} iconLeft={<SearchIcon />} /> */}
                <Card>
                  <Table
                    titles={[
                      "asset",
                      "balance",
                      "APY",
                      <div className="flex items-center space-x-2">
                        <img
                          src={RootPointsLogo}
                          alt="Root points"
                          className="w-6 h-6"
                        />
                        <span>Points</span>
                      </div>,
                      "",
                      "",
                    ]}
                    values={
                      /*  !!walletData?.nfts?.length ? walletData?.nfts[0]?.loan */
                      !!walletData?.nfts[0]?.loan.length
                        ? walletData?.nfts[0].loan
                            .sort(
                              (a, b) =>
                                Number(b.asset_amount) - Number(a.asset_amount)
                            )
                            .map((obj) => {
                              const multiplier =
                                MULTIPLIERS_PATH.borrow[
                                  obj?.token
                                    ?.id as keyof typeof MULTIPLIERS_PATH.borrow
                                ];

                              return [
                                {
                                  type: "image-text",
                                  value: {
                                    img: obj.token?.imgPath,
                                    text: obj.token?.name,
                                  },
                                },
                                {
                                  type: "text",
                                  value: (
                                    <div className="flex flex-col items-center justify-center space-y-1">
                                      <Typography>
                                        {formatNumbers(
                                          obj.asset_amount.toString() ?? 0
                                        )}
                                      </Typography>
                                      <Typography
                                        size="xs"
                                        weight="semibold"
                                        className="text-white/90"
                                      >
                                        $
                                        {formatNumbers(
                                          obj.asset_amount
                                            .mul(obj.token.usdPrice)
                                            .toString()
                                        )}
                                      </Typography>
                                    </div>
                                  ),
                                },
                                {
                                  type: "text",
                                  value: `${formatNumbers(
                                    obj.token?.protocolData?.borrowAPY ?? 0
                                  )}%`,
                                },
                                {
                                  type: "image",
                                  value: multiplier,
                                },
                                {
                                  type: "button",
                                  value: {
                                    className:
                                      "hover:bg-purple-500 hover:border-purple-500",
                                    label: "Repay",
                                    onClick: () =>
                                      setModalConfig({
                                        modal: "repay",
                                        token: obj.token,
                                      }),
                                  },
                                },
                              ];
                            })
                        : []
                    }
                    emptyMessage="You have no borrowed assets"
                  />
                </Card>
              </div>
            </>
          )}

          <div className="flex flex-col w-full gap-4">
            <Typography size="xl" weight="semibold">
              Assets to supply
            </Typography>

            {/*  <Input onChange={handleSearchLeft} iconLeft={<SearchIcon />} /> */}
            <Card>
              <Table
                titles={[
                  "asset",
                  "wallet balance",
                  "supply APY",
                  <div className="flex items-center space-x-2">
                    <img
                      src={RootPointsLogo}
                      alt="Root points"
                      className="w-6 h-6"
                    />
                    <span>Points</span>
                  </div>,
                  "",
                ]}
                values={tokens
                  .filter((token) => !token.isRtToken)
                  .map((token) => {
                    const balance =
                      walletData?.supplies.find(
                        (t) => token.address === t.address
                      )?.asset_amount ?? ZERO_DECIMAL;

                    const multiplier =
                      MULTIPLIERS_PATH.supply[
                        token?.id as keyof typeof MULTIPLIERS_PATH.supply
                      ];

                    const isComingSoon = COMING_SOON_TOKENS.includes(
                      token.address
                    );
                    const isHug = token.id === "HUG";
                    const isWowo = token.id === "WOWO";
                    

                    return [
                      {
                        type: "image-text",
                        value: {
                          img: ICONS_PATHS[token.id],
                          text: token.name,
                        },
                      },
                      {
                        type: "text",
                        value: `${formatNumbers(balance.toString())}`,
                      },
                      isWowo
                        ? {
                            type: "image-link",
                          
                            value:{ 
                              img: WowoBank,
                              url: "https://wowoproject.com/wowo-bank",
                            },
                          }
                        : {
                        type: "text",
                        value: `${formatNumbers(
                          isHug
                            ? (hugData?.apy ? hugData.apy * 100 : 0)
                            : token?.protocolData?.lendingAPY ?? 0
                        )}%`,
                      },
                      {
                        type: "image",
                        value: multiplier,
                      },
                      isComingSoon
                        ? {
                            type: "button-coming-soon",
                            value: {
                              label: "Supply",
                            },
                          }
                        : {
                            type: "button",
                            value: {
                              label: "Supply",
                              className:
                                "hover:bg-sky-500 hover:border-sky-500",
                              onClick: () =>
                                setModalConfig({
                                  modal: "supply",
                                  token,
                                }),
                            },
                          },
                    ];
                  })}
                emptyMessage="no assets were found"
              />
            </Card>
          </div>
          <div className="box-border flex flex-col w-full gap-4">
            <Typography size="xl" weight="semibold">
              Assets to borrow
            </Typography>
            {/*   <Input onChange={handleSearchRight} iconLeft={<SearchIcon />} /> */}
            <Card>
              <Table
                titles={[
                  "asset",
                  "liquidity",
                  "borrow APY",
                  <div className="flex items-center space-x-2">
                    <img
                      src={RootPointsLogo}
                      alt="Root points"
                      className="w-6 h-6"
                    />
                    <span>Points</span>
                  </div>,
                  "",
                ]}
                values={tokens
                  .filter((token) => !token.isRtToken)
                  .map((token) => {
                    const multiplier =
                      MULTIPLIERS_PATH.borrow[
                        token?.id as keyof typeof MULTIPLIERS_PATH.borrow
                      ];

                    const isComingSoon = COMING_SOON_TOKENS.includes(
                      token.address
                    );

                    const isSpecialToken = ["HUG", "EARLY", "WOWO"].includes(token.id);

                    return [
                      {
                        type: "image-text",
                        value: {
                          img: isSpecialToken ? undefined : ICONS_PATHS[token.id],
                          text: isSpecialToken ? "" : token.name,
                        },
                      },
                      {
                        type: "text",
                        value: isSpecialToken
                          ? ""
                          : `${formatNumbers(
                              token.protocolData?.availableLiquidity ?? 0
                            )}`,
                      },
                      {
                        type: "text",
                        value: isSpecialToken
                          ? ""
                          : `${formatNumbers(
                              token.protocolData?.borrowAPY ?? 0
                            )}%`,
                      },
                      {
                        type: "image",
                        value: isSpecialToken ? "" : multiplier,
                      },
                      isComingSoon
                        ? {
                            type: "button-coming-soon",
                            value: {
                              label: "Borrow",
                            },
                          }
                        : {
                            type: "button",
                            value: {
                              label: "Borrow",
                              className: `${
                                isSpecialToken
                                  ? "hidden"
                                  : "hover:bg-purple-500 hover:border-purple-500"
                              }`,
                              onClick: () =>
                                !isSpecialToken &&
                                setModalConfig({
                                  modal: "borrow",
                                  token,
                                }),
                            },
                          },
                    ];
                  })}
                emptyMessage="no assets were found"
              />
            </Card>
          </div>
        </div>
      </div>

      {(modalConfig?.modal === "supply" ||
        modalConfig?.modal === "withdraw") && (
        <SupplyManageModal
          defaultView={modalConfig.modal}
          token={modalConfig.token}
          onClickOutside={() => {
            setModalConfig(undefined);
            handleRefetch();
          }}
        />
      )}

      {modalConfig?.modal === "borrow" && (
        <BorrowManageModal
          token={modalConfig.token}
          onClickOutside={() => {
            setModalConfig(undefined);
            handleRefetch();
          }}
        />
      )}

      {modalConfig?.modal === "repay" && (
        <RepayManageModal
          borrowAsset={modalConfig.token}
          onClickOutside={() => {
            setModalConfig(undefined);
            handleRefetch();
          }}
        />
      )}
    </>
  );
};

export default Landing;
